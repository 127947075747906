import React, { useEffect, useState } from "react";
import css from "../styles/Statistics.module.scss";
import classNames from "classnames";
import DatePicker from "./DatePicker";
import moment from "moment";
import axios from "axios";
import Selector from "./Selector";
import SubMenu from "./SubMenu";

const Statistics = () => {
	const [filtersActive, setFiltersActive] = useState(false);
	const [activeDatePicker, setActiveDatePicker] = useState(false);
	const [startDay, setStartDay] = useState(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
	const [endDay, setEndDay] = useState(new Date().getTime());
	const [detailing, setDetailing] = useState(false);

	const [openDet, setOpenDet] = useState(false);

	const [data, setData] = useState([]);

	const overlay = document.querySelector(".uiOverlay");

	const getStartDay = date => {
		const today = date ? new Date(date) : new Date();
		today.setUTCHours(0, 0, 0, 0);
		return today.getTime();
	};
	const getEndDay = date => {
		const today = date ? new Date(date) : new Date();
		today.setUTCHours(23, 59, 59, 0);
		return today.getTime();
	};

	const getDate = (start, end) => {
		setStartDay(start);
		setEndDay(end);

		if (end) {
			overlay.style.display = "flex";
		}

		axios({
			method: "get",
			url: `https://transfer.traffhunt.com/reg/getStats.php?time_start=${
				getStartDay(start) / 1000
			}&time_end=${getEndDay(end) / 1000}`,
		}).then(response => {
			// if (response.statusText === "OK") {
			if (end) {
				setData(response.data.data);
				setActiveDatePicker(false);
				overlay.style.display = "none";
				// 	}
				// } else {
				// 	setActiveDatePicker(false);
				// 	overlay.style.display = "none";
				// 	console.error("Ошибка сервера!!!");
			}
		});
	};

	useEffect(() => {
		const overlay = document.querySelector(".uiOverlayFirst");
		overlay.style.display = "flex";
		const endDay = getEndDay();
		const startDay = getStartDay();
		const daysToSubtract = 7;

		const resultTimeInMillis = startDay - daysToSubtract * 24 * 60 * 60 * 1000;

		axios({
			method: "get",
			url: `https://transfer.traffhunt.com/reg/getStats.php?time_start=${
				resultTimeInMillis / 1000
			}&time_end=${endDay / 1000}`,
		}).then(response => {
			// if (response.statusText === "OK") {
			setData(response.data.data);
			setTimeout(() => {
				overlay.style.display = "none";
			}, 1000);
			// } else {
			// 	setTimeout(() => {
			// 		overlay.style.display = "none";
			// 	}, 1000);
			// 	console.error("Ошибка сервера!!!");
			// }
		});
	}, []);

	const addSpace = num => {
		return num.split(" ").map((word, index) => (
			<React.Fragment key={index}>
				{word}
				{index !== num.split(" ").length - 1 && <>&nbsp;</>}
			</React.Fragment>
		));
	};

	return (
		<div className={css.mainLayoutContentWrapper}>
			<div className={css.mainLayoutContent}>
				<div className={classNames(css.mainLayoutBody, css.fixed)}>
					<div
						data-v-3c7cbbd9=""
						className={classNames(css.loadingWrapper, css.fixed, css.mobile)}
						id="statistics"
					>
						<div data-v-3c7cbbd9="" className={css.statisticsHeader}>
							<div data-v-3c7cbbd9="" className={css.title}>
								Статистика
							</div>
							<div data-v-3c7cbbd9="" className={css.subtitle}>
								общая
							</div>
						</div>
						<div
							className={classNames(css.myFilters, filtersActive && css._active)}
							onClick={() => {
								setFiltersActive(true);
							}}
						>
							<div className={classNames(css.myFilters__title, filtersActive && css._active)}>
								<div>Фильтры</div>
								<svg className={classNames(css.svg, css.icon, "svg")}>
									<use href="icons_bundle.453fce12.svg#down"></use>
								</svg>
							</div>
							{filtersActive && (
								<div className={classNames(css.myFilters__body, filtersActive && css._active)}>
									<div className={classNames(css.foo, activeDatePicker && css._active)}>
										<div
											className={classNames(css.seeDate, activeDatePicker && css._active)}
											onClick={() => {
												setActiveDatePicker(!activeDatePicker);
											}}
										>
											<div className={css.seeDate__date}>
												{!endDay && moment(startDay).format("DD.MM.YYYY")}
												{endDay &&
													`${moment(startDay).format("DD.MM.YYYY")} - ${moment(endDay).format(
														"DD.MM.YYYY",
													)}`}
											</div>
											<svg className={css.seeDate__icon}>
												<use href="form_icons_bondle.61ddde8b.svg#calendar"></use>
											</svg>
										</div>
										<div className={classNames(css.seeDate__picker)}>
											<DatePicker getDate={getDate} />
										</div>
									</div>
									<Selector variables={["Все источники", "Источник по умолчанию"]} />
									<Selector variables={["Все ссылки"]} />
									<Selector variables={["Все страны"]} />
									<div
										className={css.detailing}
										onClick={e => {
											e.stopPropagation();
											setDetailing(!detailing);
										}}
									>
										<span>Детализация</span>
										<span className={classNames(css.circle, detailing && css._active)}></span>
									</div>
									<span className={css.line}></span>
									<div className={css.subId}>
										<div className={css.subId__label}>SUB ID</div>
										{openDet && <SubMenu setOpenDet={setOpenDet} />}
										<div className={css.subId__body} onClick={() => setOpenDet(true)}>
											<span>Sub (id)</span>
										</div>
									</div>
									<span className={css.line}></span>
									<button
										className={css.buttonActive}
										onClick={e => {
											e.stopPropagation();
											setFiltersActive(!filtersActive);
											overlay.style.display = "flex";
											setTimeout(() => {
												overlay.style.display = "none";
											}, 1500);
										}}
									>
										Применить
									</button>
								</div>
							)}
						</div>
						<div data-v-3c7cbbd9="" className={classNames(css.tableWrapper, css.shadow)}>
							<div data-v-3c7cbbd9="" className={css.tableContainer}>
								<table data-v-3c7cbbd9="" className={css.table}>
									<thead data-v-3c7cbbd9="" className={css.thead}>
										<tr data-v-3c7cbbd9="" className={css.tr}>
											<th data-v-3c7cbbd9="" className={classNames(css.th, css.left, css.thDate)}>
												Дата
											</th>
											<th data-v-3c7cbbd9="" className={css.th}>
												Переходы
											</th>
											<th data-v-3c7cbbd9="" className={css.th}>
												Регистрации
											</th>
											<th data-v-3c7cbbd9="" className={css.th}>
												Первые
												<br />
												депозиты
											</th>
											<th data-v-3c7cbbd9="" className={css.th}>
												Депозиты,
												<br />
												кол-во
											</th>
											<th data-v-3c7cbbd9="" className={css.th}>
												Депозиты,
												<br />
												сумма
											</th>
											<th data-v-3c7cbbd9="" className={css.th}>
												Повторные
												<br />
												депозиты
											</th>
											<th data-v-3c7cbbd9="" className={css.th}>
												Кол-во
												<br />
												ставок
											</th>
											<th data-v-3c7cbbd9="" className={css.th}>
												Общий
												<br />
												выигрыш
											</th>
											<th data-v-3c7cbbd9="" className={css.th}>
												Общий
												<br />
												проигрыш
											</th>
											<th data-v-3c7cbbd9="" className={css.th}>
												Общая
												<br />
												разница
											</th>
											<th data-v-3c7cbbd9="" className={css.th}>
												Доход
											</th>
											<th data-v-3c7cbbd9="" className={css.th}>
												Количество
												<br />
												квалификаций CPA
											</th>
											<th data-v-3c7cbbd9="" className={css.th}>
												Сумма
												<br />
												квалификаций CPA
											</th>
										</tr>
									</thead>
									<tbody data-v-3c7cbbd9="" className={css.tbody}>
										{data.map((el, i) => {
											return (
												<tr data-v-3c7cbbd9="" className={css.tr} key={`${el.date}-${i}`}>
													<td data-v-3c7cbbd9="" title="" className={classNames(css.th, css.left)}>
														{addSpace(el.date)}
													</td>
													<td data-v-3c7cbbd9="" title="" className={css.td}>
														{addSpace(el.transitions)}
													</td>
													<td data-v-3c7cbbd9="" title="" className={css.td}>
														{addSpace(el.reg)}
													</td>
													<td data-v-3c7cbbd9="" title="" className={css.td}>
														{addSpace(el.first_dep)}
													</td>
													<td data-v-3c7cbbd9="" title="" className={css.td}>
														{addSpace(el.dep_count)}
													</td>
													<td data-v-3c7cbbd9="" title="" className={css.td}>
														{i <= 1 && <>{addSpace(el.dep_sum)} $</>}
														{i > 1 && addSpace(`${el.dep_sum} $`)}
													</td>
													<td data-v-3c7cbbd9="" title="" className={css.td}>
														{addSpace(el.repeat_dep)}
													</td>
													<td data-v-3c7cbbd9="" title="" className={css.td}>
														{addSpace(el.bet)}
													</td>
													<td data-v-3c7cbbd9="" title="" className={css.td}>
														{i <= 1 && <>{addSpace(el.wins)} $</>}
														{i > 1 && addSpace(`${el.wins} $`)}
													</td>
													<td data-v-3c7cbbd9="" title="" className={css.td}>
														{i <= 1 && <>{addSpace(el.lost)} $</>}
														{i > 1 && addSpace(`${el.lost} $`)}
													</td>
													<td data-v-3c7cbbd9="" title="" className={css.td}>
														{i <= 1 && <>{addSpace(el.difference)} $</>}
														{i > 1 && addSpace(`${el.difference} $`)}
													</td>
													<td data-v-3c7cbbd9="" title="" className={css.td}>
														{i <= 1 && <>{addSpace(el.profit)} $</>}
														{i > 1 && addSpace(`${el.profit} $`)}
													</td>
													<td data-v-3c7cbbd9="" title="" className={css.td}>
														{addSpace(el.count_cpa)}
													</td>
													<td data-v-3c7cbbd9="" title="" className={css.td}>
														{i <= 1 && <>{addSpace(el.sum_cpa)} $</>}
														{i > 1 && addSpace(`${el.sum_cpa} $`)}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Statistics;
