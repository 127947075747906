import React from "react";
import Header from "./Header";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

const Wrapper = ({ children }) => {
	const location = useLocation();

	return (
		<>
			<div className="uiContainer">
				<div
					className={classNames(
						"uiContainerHeader",
						location.pathname === "/" && "uiContainerHeaderDashboard",
					)}
				></div>
				<div className="headerMaskWrapper">
					<div
						className={classNames(
							"headerMaskWrapper__mask",
							location.pathname === "/" && "headerMaskWrapperDashboard__mask",
						)}
					></div>
				</div>
				<div className="uiHeader">
					<Header />
				</div>
				{children}
			</div>
			<div className="uiOverlayFirst">
				<div className="uiOverlay__loaderFirst">
					<svg
						className="lds-dash-ring"
						width="80px"
						height="80px"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100"
						preserveAspectRatio="xMidYMid"
					>
						<g transform="rotate(15 50 50)">
							<animateTransform
								attributeName="transform"
								type="rotate"
								values="0 50 50;90 50 50"
								keyTimes="0;1"
								dur="0.5s"
								repeatCount="indefinite"
							></animateTransform>
							<circle
								cx="50"
								cy="50"
								r="30"
								stroke="#64269e"
								fill="none"
								strokeDasharray="23.561944901923447 188.49555921538757"
								strokeLinecap="round"
								strokeWidth="7"
								transform="rotate(0 50 50)"
							>
								<animate
									attributeName="stroke"
									values="#64269e;#442179"
									keyTimes="0;1"
									dur="0.5s"
									repeatCount="indefinite"
								></animate>
							</circle>
							<circle
								cx="50"
								cy="50"
								r="30"
								stroke="#442179"
								fill="none"
								strokeDasharray="23.561944901923447 188.49555921538757"
								strokeLinecap="round"
								strokeWidth="7"
								transform="rotate(90 50 50)"
							>
								<animate
									attributeName="stroke"
									values="#442179;#241a4f"
									keyTimes="0;1"
									dur="0.5s"
									repeatCount="indefinite"
								></animate>
							</circle>
							<circle
								cx="50"
								cy="50"
								r="30"
								stroke="#241a4f"
								fill="none"
								strokeDasharray="23.561944901923447 188.49555921538757"
								strokeLinecap="round"
								strokeWidth="7"
								transform="rotate(180 50 50)"
							>
								<animate
									attributeName="stroke"
									values="#241a4f;#181841"
									keyTimes="0;1"
									dur="0.5s"
									repeatCount="indefinite"
								></animate>
							</circle>
							<circle
								cx="50"
								cy="50"
								r="30"
								stroke="#181841"
								fill="none"
								strokeDasharray="23.561944901923447 188.49555921538757"
								strokeLinecap="round"
								strokeWidth="7"
								transform="rotate(270 50 50)"
							>
								<animate
									attributeName="stroke"
									values="#181841;#64269e"
									keyTimes="0;1"
									dur="0.5s"
									repeatCount="indefinite"
								></animate>
							</circle>
						</g>
					</svg>
				</div>
			</div>
			<div className="uiOverlay">
				<div className="uiOverlay__loader">
					<svg
						className="lds-dash-ring"
						width="80px"
						height="80px"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100"
						preserveAspectRatio="xMidYMid"
					>
						<g transform="rotate(15 50 50)">
							<animateTransform
								attributeName="transform"
								type="rotate"
								values="0 50 50;90 50 50"
								keyTimes="0;1"
								dur="0.5s"
								repeatCount="indefinite"
							></animateTransform>
							<circle
								cx="50"
								cy="50"
								r="30"
								stroke="#fff"
								fill="none"
								strokeDasharray="23.561944901923447 188.49555921538757"
								strokeLinecap="round"
								strokeWidth="7"
								transform="rotate(0 50 50)"
							>
								<animate
									attributeName="stroke"
									values="#fff;#fff"
									keyTimes="0;1"
									dur="0.5s"
									repeatCount="indefinite"
								></animate>
							</circle>
							<circle
								cx="50"
								cy="50"
								r="30"
								stroke="#fff"
								fill="none"
								strokeDasharray="23.561944901923447 188.49555921538757"
								strokeLinecap="round"
								strokeWidth="7"
								transform="rotate(90 50 50)"
							>
								<animate
									attributeName="stroke"
									values="#fff;#fff"
									keyTimes="0;1"
									dur="0.5s"
									repeatCount="indefinite"
								></animate>
							</circle>
							<circle
								cx="50"
								cy="50"
								r="30"
								stroke="#fff"
								fill="none"
								strokeDasharray="23.561944901923447 188.49555921538757"
								strokeLinecap="round"
								strokeWidth="7"
								transform="rotate(180 50 50)"
							>
								<animate
									attributeName="stroke"
									values="#fff;#fff"
									keyTimes="0;1"
									dur="0.5s"
									repeatCount="indefinite"
								></animate>
							</circle>
							<circle
								cx="50"
								cy="50"
								r="30"
								stroke="#fff"
								fill="none"
								strokeDasharray="23.561944901923447 188.49555921538757"
								strokeLinecap="round"
								strokeWidth="7"
								transform="rotate(270 50 50)"
							>
								<animate
									attributeName="stroke"
									values="#fff;#fff"
									keyTimes="0;1"
									dur="0.5s"
									repeatCount="indefinite"
								></animate>
							</circle>
						</g>
					</svg>
				</div>
			</div>
		</>
	);
};

export default Wrapper;
