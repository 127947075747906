import React, { useEffect } from "react";
import css from "../styles/Contacts.module.scss";
import classNames from "classnames";

const Contacts = () => {
	useEffect(() => {
		const container = document.querySelector(".uiContainer");
		container.style.padding = "60px 0px 0px 0px";

		return () => (container.style.padding = "60px var(--container)");
	}, []);

	return (
		<div className={css.main_layout_content_wrapper}>
			<div className={css.main_layout_content}>
				<div className={css.main_layout_body}>
					<div className={css.contacts_main_title}>Контакты</div>
					<div className={css.contacts_content_container}>
						<div className={classNames(css.contacts_body, css.hide_scrollbar)}>
							<div className={css.contacts_container}>
								<div className={css.contacts_title}>Revshare</div>
								<div className={css.contacts_socials_container}>
									<div className={css.contacts_social_container}>
										<svg className={classNames(css.svg, css.contacts_social_icon)}>
											<use href="icons_bundle.453fce12.svg#telegram"></use>
										</svg>
										<a
											className={classNames(css.contacts_social)}
											href="https://t.me/partners_1w"
											target="_blank"
											rel="noreferrer"
										>
											@partners_1w
										</a>
									</div>
									<div className={css.contacts_social_container}>
										<svg className={classNames(css.svg, css.contacts_social_icon)}>
											<use href="icons_bundle.453fce12.svg#mail"></use>
										</svg>
										<a
											className={classNames(css.contacts_social)}
											href="mailto:partners@1win.pro"
											target="_blank"
											rel="noreferrer"
										>
											partners@1win.pro
										</a>
									</div>
								</div>
							</div>
							<div className={css.contacts_container}>
								<div className={css.contacts_title}>CPA</div>
								<div className={css.contacts_socials_container}>
									<div className={css.contacts_social_container}>
										<svg className={classNames(css.svg, css.contacts_social_icon)}>
											<use href="icons_bundle.453fce12.svg#telegram"></use>
										</svg>
										<a
											className={classNames(css.contacts_social)}
											href="https://t.me/cpa_1win"
											target="_blank"
											rel="noreferrer"
										>
											@cpa_1win
										</a>
									</div>
									<div className={css.contacts_social_container}>
										<svg className={classNames(css.svg, css.contacts_social_icon)}>
											<use href="icons_bundle.453fce12.svg#skype"></use>
										</svg>
										<a
											className={classNames(css.contacts_social)}
											href="skype:live:9dcd8e20f3d38855"
											target="_blank"
											rel="noreferrer"
										>
											Skype
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contacts;
