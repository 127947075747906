/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import css from "../styles/Statistics.module.scss";
import styles from "../styles/Dashboard.module.scss";
import classNames from "classnames";
import DatePicker from "./DatePicker";
import moment from "moment";
import axios from "axios";
import Selector from "./Selector";

const Dashboard = () => {
	const [filtersActive, setFiltersActive] = useState(false);
	const [activeDatePicker, setActiveDatePicker] = useState(false);
	const [startDay, setStartDay] = useState(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
	const [endDay, setEndDay] = useState(new Date().getTime());

	const [activeSelector, setActiveSelector] = useState(false);
	const [currentSelector, setCurrentSelector] = useState("За всё время");

	const [bottomHeight, setBottomHeight] = useState(0);

	const [data, setData] = useState([]);

	const overlay = document.querySelector(".uiOverlay");

	useEffect(() => {
		const pickerHeight = document
			.querySelector(".react-datepicker__month")
			.getBoundingClientRect().height;
		console.log(pickerHeight);
		setBottomHeight(pickerHeight);
	}, [activeDatePicker]);

	const getStartDay = date => {
		const today = date ? new Date(date) : new Date();
		today.setUTCHours(0, 0, 0, 0);
		return today.getTime();
	};
	const getEndDay = date => {
		const today = date ? new Date(date) : new Date();
		today.setUTCHours(23, 59, 59, 0);
		return today.getTime();
	};

	const getDate = (start, end) => {
		setStartDay(start);
		setEndDay(end);

		if (end) {
			overlay.style.display = "flex";
		}

		axios({
			method: "get",
			url: `https://transfer.traffhunt.com/reg/getDash.php?time_start=${
				getStartDay(start) / 1000
			}&time_end=${getEndDay(end) / 1000}`,
		}).then(response => {
			// if (response.statusText === "OK") {
			if (end) {
				setData(response.data.data);
				setActiveDatePicker(false);
				overlay.style.display = "none";
			}
			// } else {
			// 	setActiveDatePicker(false);
			// 	overlay.style.display = "none";
			// 	console.error("Ошибка сервера!!!");
			// }
		});
	};

	useEffect(() => {
		const overlay = document.querySelector(".uiOverlayFirst");
		overlay.style.display = "flex";
		const endDay = getEndDay();

		const startOfDecember2022 = new Date("2022-12-01T00:00:00Z");

		axios({
			method: "get",
			url: `https://transfer.traffhunt.com/reg/getDash.php?time_start=${
				getStartDay(startOfDecember2022) / 1000
			}&time_end=${endDay / 1000}`,
		}).then(response => {
			// if (response.statusText === "OK") {
			setData(response.data.data);
			setTimeout(() => {
				overlay.style.display = "none";
			}, 1000);
			// } else {
			// 	setTimeout(() => {
			// 		overlay.style.display = "none";
			// 	}, 1000);
			// 	console.error("Ошибка сервера!!!");
			// }
		});
	}, []);

	const getAllTime = () => {
		const overlay = document.querySelector(".uiOverlay");
		overlay.style.display = "flex";
		const endDay = getEndDay();

		const startOfDecember2022 = new Date("2022-12-01T00:00:00Z");

		axios({
			method: "get",
			url: `https://transfer.traffhunt.com/reg/getDash.php?time_start=${
				getStartDay(startOfDecember2022) / 1000
			}&time_end=${endDay / 1000}`,
		}).then(response => {
			// if (response.statusText === "OK") {
			setData(response.data.data);

			overlay.style.display = "none";
			// } else {
			// 	overlay.style.display = "none";

			// 	console.error("Ошибка сервера!!!");
			// }
		});
	};

	const getMonth = () => {
		const overlay = document.querySelector(".uiOverlay");
		overlay.style.display = "flex";
		const endDay = getEndDay();
		const startDay = getStartDay();
		const daysToSubtract = 30;

		const resultTimeInMillis = startDay - daysToSubtract * 24 * 60 * 60 * 1000;

		axios({
			method: "get",
			url: `https://transfer.traffhunt.com/reg/getDash.php?time_start=${
				resultTimeInMillis / 1000
			}&time_end=${endDay / 1000}`,
		}).then(response => {
			// if (response.statusText === "OK") {
			setData(response.data.data);
			overlay.style.display = "none";
			// } else {
			// 	overlay.style.display = "none";
			// 	console.error("Ошибка сервера!!!");
			// }
		});
	};

	const getWeek = () => {
		const overlay = document.querySelector(".uiOverlay");
		overlay.style.display = "flex";
		const endDay = getEndDay();
		const startDay = getStartDay();
		const daysToSubtract = 7;

		const resultTimeInMillis = startDay - daysToSubtract * 24 * 60 * 60 * 1000;

		axios({
			method: "get",
			url: `https://transfer.traffhunt.com/reg/getDash.php?time_start=${
				resultTimeInMillis / 1000
			}&time_end=${endDay / 1000}`,
		}).then(response => {
			// if (response.statusText === "OK") {
			setData(response.data.data);
			overlay.style.display = "none";
			// } else {
			// 	overlay.style.display = "none";
			// 	console.error("Ошибка сервера!!!");
			// }
		});
	};

	const getToday = () => {
		const overlay = document.querySelector(".uiOverlay");
		overlay.style.display = "flex";
		const endDay = getEndDay();
		const startDay = getStartDay();

		axios({
			method: "get",
			url: `https://transfer.traffhunt.com/reg/getDash.php?time_start=${startDay / 1000}&time_end=${
				endDay / 1000
			}`,
		}).then(response => {
			// if (response.statusText === "OK") {
			setData(response.data.data);
			overlay.style.display = "none";
			// } else {
			// 	overlay.style.display = "none";
			// 	console.error("Ошибка сервера!!!");
			// }
		});
	};

	return (
		<div className={css.mainLayoutContentWrapper}>
			<div className={css.mainLayoutContent}>
				<div className={classNames(css.mainLayoutBody, css.fixed)}>
					<div className={classNames(css.loadingWrapper, css.fixed, css.mobile)} id="statistics">
						<div className={css.statisticsHeader}>
							<div className={css.title}>DASHBOARD</div>
						</div>
						<div
							className={classNames(
								styles.loadingWrapper,
								styles.dashboardInfo,
								styles.shadow,
								styles.mobile,
							)}
						>
							<div className={classNames(styles.infoHeader, styles.titleContainer)}>
								<svg className={classNames(styles.svg, styles.titleIcon)}>
									<use href="icons_bundle.453fce12.svg#clock"></use>
								</svg>
								<div
									className={classNames(
										styles.vmFieldWrapper,
										styles.vmField,
										styles.vmFieldSource,
										styles.required,
										styles.dashboardTime,
									)}
									icon="arrow-drop-down"
									onClick={() => {
										setActiveSelector(!activeSelector);
									}}
								>
									<div className={styles.vmSelectSontainer}>
										<div className={styles.vmSelectInputBlock}>
											<input
												className={classNames(
													styles.vmInput,
													styles.vmSelectInput,
													styles.validator,
												)}
											/>
											<div className={classNames(styles.vmSelectInputOverlay)}></div>
											<div className={styles.vmSelectTrigger}>
												<svg className={styles.vmIcon}>
													<use href="form_icons_bondle.61ddde8b.svg#arrow-drop-down"></use>
												</svg>
											</div>
										</div>
									</div>
									<div className={classNames(styles.selector, activeSelector && styles._active)}>
										<div
											className={classNames(
												styles.selectorItem,
												currentSelector === "За всё время" && styles._active,
											)}
											onClick={() => {
												getAllTime();
												setCurrentSelector("За всё время");
											}}
										>
											<span>За всё время</span>
										</div>
										<div
											className={classNames(
												styles.selectorItem,
												currentSelector === "Месяц" && styles._active,
											)}
											onClick={() => {
												getMonth();
												setCurrentSelector("Месяц");
											}}
										>
											<span>Месяц</span>
										</div>
										<div
											className={classNames(
												styles.selectorItem,
												currentSelector === "Неделя" && styles._active,
											)}
											onClick={() => {
												getWeek();
												setCurrentSelector("Неделя");
											}}
										>
											<span>Неделя</span>
										</div>
										<div
											className={classNames(
												styles.selectorItem,
												currentSelector === "Сегодня" && styles._active,
											)}
											onClick={() => {
												getToday();
												setCurrentSelector("Сегодня");
											}}
										>
											<span>Сегодня</span>
										</div>
									</div>
								</div>
								<div className={styles.titleText}>{currentSelector}</div>
							</div>
							<div className={styles.infoBody}>
								<div className={styles.infoBlock}>
									<div className={styles.infoBlockText}>Переходы:</div>
									<div className={styles.infoBlockValue}>{data.transitions}</div>
								</div>
								<div className={styles.infoBlock}>
									<div className={styles.infoBlockText}>Регистрации:</div>
									<div className={styles.infoBlockValue}>{data.reg}</div>
								</div>
								<div className={styles.infoBlock}>
									<div className={styles.infoBlockText}>Первые депозиты:</div>
									<div className={styles.infoBlockValue}>{data.first_dep}</div>
								</div>
								<div className={styles.infoBlock}>
									<div className={styles.infoBlockText}>Кол-во пополнений депозитов:</div>
									<div className={styles.infoBlockValue}>{data.repeat_dep}</div>
								</div>
								<div className={styles.infoBlock}>
									<div className={styles.infoBlockText}>Ратио по регистрациям:</div>
									<div className={styles.infoBlockValue}>{data.reg_ratio}</div>
								</div>
								<div className={styles.infoBlock}>
									<div className={styles.infoBlockText}>Ратио по депозитам:</div>
									<div className={styles.infoBlockValue}>{data.ratio_dep}</div>
								</div>
								<div className={styles.infoBlock}>
									<div className={styles.infoBlockText}>Сумма депозитов:</div>
									<div className={styles.infoBlockValue}>{data.sum_dep} $</div>
								</div>
								<div className={styles.infoBlock}>
									<div className={styles.infoBlockText}>Стоимость перехода:</div>
									<div className={styles.infoBlockValue}>{data.cost_lead} $</div>
								</div>
								<div className={styles.infoBlock}>
									<div className={styles.infoBlockText}>Средний доход с игрока:</div>
									<div className={styles.infoBlockValue}>{data.profit_lead} $</div>
								</div>
							</div>
							<div className={styles.infoFooter}>
								<div className={styles.infoFooterText}>Доход</div>
								<div className={styles.infoFooterValue}>{data.profit} $</div>
							</div>
						</div>
						<div
							className={classNames(css.myFilters, filtersActive && css._active)}
							onClick={() => {
								setFiltersActive(true);
							}}
						>
							<div className={classNames(css.myFilters__body, filtersActive && css._active)}>
								<div className={classNames(css.foo, activeDatePicker && css._active)}>
									<div
										className={classNames(css.seeDate, activeDatePicker && css._active)}
										onClick={() => {
											setActiveDatePicker(!activeDatePicker);
										}}
									>
										<div className={css.seeDate__date}>
											{!endDay && moment(startDay).format("DD.MM.YYYY")}
											{endDay &&
												`${moment(startDay).format("DD.MM.YYYY")} - ${moment(endDay).format(
													"DD.MM.YYYY",
												)}`}
										</div>
										<svg className={css.seeDate__icon}>
											<use href="form_icons_bondle.61ddde8b.svg#calendar"></use>
										</svg>
									</div>
									<div className={classNames(css.seeDate__picker)}>
										<DatePicker getDate={getDate} />
									</div>
								</div>
								<Selector variables={["Все источники", "Источник по умолчанию"]} />
								<Selector variables={["Все ссылки"]} />
								<Selector variables={["Все страны"]} />
								<button
									className={css.buttonActive}
									onClick={e => {
										e.stopPropagation();
										setFiltersActive(!filtersActive);
										overlay.style.display = "flex";
										setTimeout(() => {
											overlay.style.display = "none";
										}, 1500);
									}}
								>
									Применить
								</button>
							</div>
						</div>
						<div
							style={activeDatePicker ? { height: `${bottomHeight}px` } : { height: "50px" }}
						></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
