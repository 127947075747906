import React, { useState } from "react";
import css from "../styles/FAQ.module.scss";
import classNames from "classnames";

const FAQ = () => {
	const [active, setActive] = useState(0);

	return (
		<div className={css.main_layout_content_wrapper}>
			<div className={css.main_layout_content}>
				<div className={classNames(css.main_layout_body, css.fixed)} id="help">
					<div className={css.title}>Вопрос / Ответ</div>
					<div className={css.questions}>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 1 && css.activeHeader)}
								onClick={() => {
									if (active === 1) {
										return setActive(0);
									}
									setActive(1);
								}}
							>
								1. Как вывести средства с партнерской программы?
							</div>
							<div className={classNames(css.question_body, active === 1 && css.activeBody)}>
								Чтобы вывести средства с партнерского аккаунта, перейдите в раздел «Выводы». В
								появившемся модальном окне введите почту от вашего игрового аккаунта 1win и укажите
								актуальный номер телефона. На этот номер мы вышлем код подтверждения, который нужно
								будет ввести в соответствующее поле. После этого денежные средства будут перечислены
								на ваш игровой счет и станут доступны для вывода на удобную вам платежную систему.
							</div>
						</div>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 2 && css.activeHeader)}
								onClick={() => {
									if (active === 2) {
										return setActive(0);
									}
									setActive(2);
								}}
							>
								2. Что такое партнерская программа?
							</div>
							<div className={classNames(css.question_body, active === 2 && css.activeBody)}>
								Партнерская программа ⏤ это вид сотрудничества, при котором Букмекерская компания
								начисляет Партнеру определенный процент от дохода с привлеченных им игроков.
							</div>
						</div>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 3 && css.activeHeader)}
								onClick={() => {
									if (active === 3) {
										return setActive(0);
									}
									setActive(3);
								}}
							>
								3. Как это работает?
							</div>
							<div className={classNames(css.question_body, active === 3 && css.activeBody)}>
								Если у вас есть интернет-сайт или любой другой ресурс с аудиторией, вы можете
								разместить на нем партнерскую ссылку или баннер, ведущий на сайт 1win. Любой игрок,
								пришедший с вашего ресурса и открывший игровой счет, будет приносить вам прибыль.
								Партнерские ссылки и баннеры доступны в разделе «PR-инструменты» партнерского
								кабинета.
							</div>
						</div>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 4 && css.activeHeader)}
								onClick={() => {
									if (active === 4) {
										return setActive(0);
									}
									setActive(4);
								}}
							>
								4. Какие есть ограничения для вывода денежных средств?
							</div>
							<div className={classNames(css.question_body, active === 4 && css.activeBody)}>
								Для вывода денежных средств вам нужно привлечь не менее 10 активных игроков,
								суммарный депозит которых составит 5000₽, 70$ или 70€. Вывод средств доступен один
								раз в неделю по вторникам. Вывести можно всю сумму на балансе. Если Партнер не имеет
								необходимых показателей для выплаты комиссионных, то средства сохраняются до тех
								пор, пока не накопится нужная сумма депозитов или количество активных клиентов.
								Минимальный вывод средств с партнерского счета: 5₽, 2$ или 2€
							</div>
						</div>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 5 && css.activeHeader)}
								onClick={() => {
									if (active === 5) {
										return setActive(0);
									}
									setActive(5);
								}}
							>
								5. Прошло 24 часа, но средства с партнерского счета не перечислились на мой игровой
								счет. Почему?{" "}
							</div>
							<div className={classNames(css.question_body, active === 5 && css.activeBody)}>
								Время вывода денежных средств может увеличиться до 3-5 рабочих дней, если службой
								безопасности было принято решение дополнительно проверить Партнера.
							</div>
						</div>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 6 && css.activeHeader)}
								onClick={() => {
									if (active === 6) {
										return setActive(0);
									}
									setActive(6);
								}}
							>
								6. Как распределяется доход?
							</div>
							<div className={classNames(css.question_body, active === 6 && css.activeBody)}>
								Вы будете получать от 50% до 60% с проигрышей игроков, которые зарегистрируются по
								вашей партнерской ссылке или при регистрации укажут ваш личный промокод. Чем больше
								игроков, тем больше прибыли вы получаете!
							</div>
						</div>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 7 && css.activeHeader)}
								onClick={() => {
									if (active === 7) {
										return setActive(0);
									}
									setActive(7);
								}}
							>
								7. Где я могу посмотреть привлеченных игроков?
							</div>
							<div className={classNames(css.question_body, active === 7 && css.activeBody)}>
								Вся информация по привлеченным игрокам доступна в личном кабинете Партнера. Раздел
								«Статистика» обновляется в режиме реального времени.
							</div>
						</div>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 8 && css.activeHeader)}
								onClick={() => {
									if (active === 8) {
										return setActive(0);
									}
									setActive(8);
								}}
							>
								8. Как мне привлекать пользователей?
							</div>
							<div className={classNames(css.question_body, active === 8 && css.activeBody)}>
								В личном кабинете Партнера вы можете создать реферальную ссылку или промокод и
								разместить их на ресурсах с активной аудиторией. Формат рекламы 1win остается
								полностью на ваше усмотрение. Внимание: не используйте запрещенные виды трафика,
								перечисленные в правилах партнерского соглашения.
							</div>
						</div>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 9 && css.activeHeader)}
								onClick={() => {
									if (active === 9) {
										return setActive(0);
									}
									setActive(9);
								}}
							>
								9. Как получить повышенный процент комиссионных?
							</div>
							<div className={classNames(css.question_body, active === 9 && css.activeBody)}>
								Повышение процента рассчитывается индивидуально и зависит от ваших достижений.
								Свяжитесь с вашим личным менеджером, чтобы узнать подробности.
							</div>
						</div>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 10 && css.activeHeader)}
								onClick={() => {
									if (active === 10) {
										return setActive(0);
									}
									setActive(10);
								}}
							>
								10. Что такое «Ратио» по депозитам и регистрациям?
							</div>
							<div className={classNames(css.question_body, active === 10 && css.activeBody)}>
								Ратио по регистрациям ⏤ фактическое соотношение числа посетителей ресурса и
								количества произведенных ими регистраций. Ратио по депозитам ⏤ фактическое
								соотношение суммы депозитов к количеству зарегистрированных пользователей.
							</div>
						</div>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 11 && css.activeHeader)}
								onClick={() => {
									if (active === 11) {
										return setActive(0);
									}
									setActive(11);
								}}
							>
								11. Как часто обновляется статистика в личном кабинете?
							</div>
							<div className={classNames(css.question_body, active === 11 && css.activeBody)}>
								Статистика обновляется в режиме реального времени. Как только у привлеченного игрока
								рассчитывается проигранная ставка, вам автоматически начисляется доход.
							</div>
						</div>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 12 && css.activeHeader)}
								onClick={() => {
									if (active === 12) {
										return setActive(0);
									}
									setActive(12);
								}}
							>
								12. Что такое промокод и как его создать?
							</div>
							<div className={classNames(css.question_body, active === 12 && css.activeBody)}>
								Промокод ⏤ комбинация символов, которую вводят игроки и закрепляются за вами. Как
								создать промокод? Зайдите в партнерский кабинет → PR-инструменты → Промокоды →
								выберите источник и укажите название промокода. Используйте простые и запоминающиеся
								названия не короче 4 символов. Вы можете использовать цифры и латинские буквы.{" "}
							</div>
						</div>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 13 && css.activeHeader)}
								onClick={() => {
									if (active === 13) {
										return setActive(0);
									}
									setActive(13);
								}}
							>
								13. Сколько времени занимает вывод с игрового счета?
							</div>
							<div className={classNames(css.question_body, active === 13 && css.activeBody)}>
								Время вывода денежных средств ⏤ 1-60 минут. Комиссия за вывод средств, заработанных
								по партнерской программе, отсутствует.
							</div>
						</div>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 14 && css.activeHeader)}
								onClick={() => {
									if (active === 14) {
										return setActive(0);
									}
									setActive(14);
								}}
							>
								14. Сколько занимает перевод с партнерского аккаунта на игровой счет?
							</div>
							<div className={classNames(css.question_body, active === 14 && css.activeBody)}>
								Перевод производится в течение 24 часов, если службой безопасности не выявлены
								нарушения правил Партнерского соглашения.
							</div>
						</div>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 15 && css.activeHeader)}
								onClick={() => {
									if (active === 15) {
										return setActive(0);
									}
									setActive(15);
								}}
							>
								15. Какие существуют модели выплат?
							</div>
							<div className={classNames(css.question_body, active === 15 && css.activeBody)}>
								RevShare ⏤ вы получаете процент от прибыли компании за каждого привлеченного вами
								клиента. CPA ⏤ вы получаете фиксированную ставку за каждого привлеченного игрока,
								прошедшего KPI. Hybrid ⏤ вы можете комбинировать офферы. Чтобы перейти на модель СPA
								или Hybrid, свяжитесь с личным менеджером.
							</div>
						</div>
						<div className={classNames(css.question, css.shadow)}>
							<div
								className={classNames(css.question_header, active === 16 && css.activeHeader)}
								onClick={() => {
									if (active === 16) {
										return setActive(0);
									}
									setActive(16);
								}}
							>
								16. Что такое реферальная ссылка и из чего она состоит?
							</div>
							<div className={classNames(css.question_body, active === 16 && css.activeBody)}>
								Реферальная ссылка — уникальная ссылка, которая предоставляется партнерам для
								привлечения новых участников. Если новый пользователь зарегистрируется по вашей
								ссылке, то вы получите вознаграждение, предусмотренное партнерской программой.
								Реферальная ссылка состоит из двух основных компонентов: общего домена и вашего
								уникального кода, отделенного символом #. Вы можете сформировать свою личную ссылку
								разделе PR-инструменты &gt; Ссылки. По желанию ссылку можно сократить. Каждая ссылка
								содержит идентификатор, который позволяет нам отслеживать ваши достижения. Если
								ссылка будет заменена, мы оперативно уведомим вас об этом по почте и в партнерском
								чате.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FAQ;
