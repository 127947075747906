import React from "react";
import css from "../styles/SubMenu.module.scss";
import classNames from "classnames";
import Selector2 from "./Selector2";

const SubMenu = ({ setOpenDet }) => {
	return (
		<>
			<div data-v-e4975e44="" className={css.overlay}></div>
			<div data-v-e4975e44="" className={classNames(css.wrapper, css._isVisible)}>
				<div
					className={classNames(
						css.StatisticsSubIdModal_root_g06uJ,
						css.Modal_root_NLdK_,
						css.hide_scrollbar,
					)}
					data-modal="statistics-sub-id-modal"
				>
					<div
						className={classNames(
							css.Modal_body_t31JW,
							css.Modal_defaultBody_jxjP9,
							css.StatisticsSubIdModal_modalBody_KpXyw,
						)}
					>
						<svg
							data-v-5d70a58b=""
							className={classNames(css.svg, css.Modal_close_v7b3H)}
							onClick={() => {
								setOpenDet(false);
							}}
						>
							<use href="icons_bundle.453fce12.svg#cross"></use>
						</svg>
						<div className={css.modalContainer}>
							<div className={css.modal_block}>
								<form>
									<Selector2 title={"SubID 1"} />
									<Selector2 title={"SubID 2"} />
									<Selector2 title={"SubID 3"} />
									<Selector2 title={"SubID 4"} />
									<Selector2 title={"SubID 5"} />
									<button
										className={classNames(
											css.vm_button,
											css.button,
											css.success,
											css.circle,
											css.center,
											css.padding_top,
										)}
										type="submit"
									>
										Применить
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SubMenu;
