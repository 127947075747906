import React from "react";
import "./styles/globals.scss";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Wrapper from "./components/Warapper";
import Statistics from "./components/Statistics";
import Dashboard from "./components/Dashboard";
import Source from "./components/Source";
import FAQ from "./components/FAQ";
import Contacts from "./components/Contacts";

const router = createBrowserRouter([
	{
		path: "/",
		element: (
			<Wrapper>
				<Dashboard />
			</Wrapper>
		),
	},
	{
		path: "/statistics",
		element: (
			<Wrapper>
				<Statistics></Statistics>
			</Wrapper>
		),
	},
	{
		path: "/source",
		element: (
			<Wrapper>
				<Source></Source>
			</Wrapper>
		),
	},
	{
		path: "/faq",
		element: (
			<Wrapper>
				<FAQ/>
			</Wrapper>
		),
	},
	{
		path: "/contacts",
		element: (
			<Wrapper>
				<Contacts/>
			</Wrapper>
		),
	},
]);

ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>,
);
