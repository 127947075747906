/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import css from "../styles/SubMenu.module.scss";
import classNames from "classnames";

const Selector2 = ({
	title,
	variables = [
		"",
		"0000111001010",
		"142412lmklk214124",
		"21124214242144",
		"001001002030wefewf",
		"232tryhengbdfdv",
		"32t4rhenfbvdfa",
	],
}) => {
	const [focus, setFocus] = useState(false);
	const [select, setSelect] = useState("");
	const [sortArr, setSortArr] = useState([]);
	const [value, setValue] = useState("");

	useEffect(() => {
		setSortArr(variables);
	}, []);

	const handlerFocus = () => {
		setFocus(true);
	};

	const handlerBlur = str => {
		setTimeout(() => {
			setFocus(false);
			if (!str) {
				setValue(select);
			} else {
				console.log(str);
				setValue(str);
			}
		}, 300);
	};

	const handlerChange = e => {
		const { value } = e.target;
		if (value === "") {
			setValue("");
			return setSortArr(variables);
		}

		const newArr = [];

		variables.forEach(item => {
			if (item.search(value) !== -1) {
				newArr.push(item);
			}
		});
		setValue(value);
		setSortArr(newArr);
	};

	return (
		<div
			className={classNames(
				css.vm_field_wrapper,
				css.vm_field,
				css.vm_field_sub_id_1,
				css._disabled,
			)}
			style={{ zIndex: 5 }}
		>
			<label className={css.vm_label}>{title}</label>
			<div className={css.vm_select_container}>
				<div className={css.vm_select_input_block}>
					<input
						className={classNames(
							css.validator,
							css.vm_input,
							css.vm_select_input,
							focus && css._active,
						)}
						value={value || ""}
						placeholder={focus ? "Поиск" : select}
						type="text"
						name="VMSearchField"
						onChange={handlerChange}
						onFocus={handlerFocus}
						onBlur={() => handlerBlur()}
					/>
					<div className={css.vm_select_trigger} onClick={handlerFocus}>
						<svg className={classNames(css.vm_icon, focus && css._active)}>
							<use href="icons_bundle.453fce12.svg#down"></use>
						</svg>
					</div>
				</div>
				<div className={classNames(css.variables, focus && css._active)}>
					{sortArr.map(item => {
						return (
							<div
								className={css.variables__item}
								key={`${item}`}
								onClick={() => {
									setValue(item);
									setSelect(item);
									handlerBlur(item);
								}}
							>
								<span>{item}</span>
								{item === select && <span className={css.variables__circle}></span>}
							</div>
						);
					})}
				</div>
			</div>{" "}
		</div>
	);
};

export default Selector2;
