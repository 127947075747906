import React, { useEffect, useState } from "react";
import css from "../styles/Source.module.scss";
import classNames from "classnames";

const Source = () => {
	const [openModal, setOpenModal] = useState(false);

	return (
		<>
			<div className={css.main_layout_content}>
				<div className={classNames(css.main_layout_body, css.fixed)} page="1">
					<div className={classNames(css.loading_wrapper, css.fixed, css.mobile)} id="sources">
						<div className={css.group_header}>
							<div className={classNames(css.group_header_item, css.title_text)}>
								<span className={css.text}>Источники</span>
							</div>
							<button
								className={classNames(css.group_header_item, css.mini, css.button, css.success)}
								onClick={() => setOpenModal(true)}
							>
								<span className={css.text}>Создать источник</span>
							</button>
						</div>
						<div className={classNames(css.table_wrapper, css.shadow)} style={{ height: "610px" }}>
							<div className={css.table_container}>
								<table className={css.table}>
									<thead className={css.thead}>
										<tr className={css.tr}>
											<th className={css.th}>Имя</th>
											<th className={css.th}>Тип</th>
											<th className={css.th}>Ссылка</th>
											<th className={classNames(css.th, css.actions)}>Действия</th>
										</tr>
									</thead>
									<tbody className={css.tbody}>
										<tr className={css.tr}>
											<td className={css.td}>Источник по умолчанию</td>
											<td className={css.td}>Сайт</td>
											<td className={css.td}>Ссылки нет</td>
											<td className={classNames(css.td, css.actions)}></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className={css.pagination}>
							<div className={classNames(css.page, css.active)}>1</div>
						</div>
					</div>
				</div>
			</div>
			{openModal && <Modal setOpenModal={setOpenModal} />}
		</>
	);
};

const Modal = ({ setOpenModal }) => {
	const [data, setData] = useState({});

	const [validator1, setValidator1] = useState("gray");
	const [validator2, setValidator2] = useState("gray");
	const [validator3, setValidator3] = useState("gray");

	const handleChange = e => {
		const { name, value } = e.target;

		const newData = { ...data };

		if (name === "source_name") {
			if (value === "") {
				setValidator1("orange");
			} else {
				setValidator1("green");
			}
		}
		if (name === "link_page") {
			if (value === "") {
				setValidator2("orange");
			} else {
				setValidator2("green");
			}
		}
		if (name === "comment") {
			if (value === "") {
				setValidator3("orange");
			} else {
				setValidator3("green");
			}
		}

		newData[name] = value;
		setData(newData);
	};

	const submit = () => {
		if (
			validator1 === "gray" ||
			validator1 === "red" ||
			validator2 === "gray" ||
			validator2 === "red" ||
			validator3 === "gray" ||
			validator3 === "red"
		) {
			if (validator1 === "gray" || validator1 === "red") {
				setValidator1("red");
			}
			if (validator2 === "gray" || validator2 === "red") {
				setValidator2("red");
			}
			if (validator3 === "gray" || validator3 === "red") {
				setValidator3("red");
			}

			return;
		}
	};

	return (
		<div>
			<div className={css.overlay}></div>
			<div className={classNames(css.wrapper, css.isVisible)}>
				<div
					className={classNames(
						css.SourceCreateModal_root_ZvFxA,
						css.Modal_root_NLdK_,
						css.hide_scrollbar,
					)}
				>
					<div
						className={classNames(
							css.Modal_body_t31JW,
							css.Modal_defaultBody_jxjP9,
							css.SourceCreateModal_modalBody_NXniA,
						)}
					>
						<svg
							className={classNames(css.svg, css.Modal_close_v7b3H)}
							onClick={() => setOpenModal(false)}
						>
							<use href="icons_bundle.453fce12.svg#cross"></use>
						</svg>
						<div className={css.modalContainer}>
							<div className={css.modal_block}>
								<form>
									<div
										className={classNames(
											css.vm_field_wrapper,
											css.vm_field,
											css.vm_field_source_name,
											css.required,
										)}
									>
										<label className={css.vm_label}>Имя источника</label>
										<div className={css.vm_input_block}>
											<input
												className={classNames(css.validator, css.vm_input)}
												required={true}
												type="text"
												name="source_name"
												tabindex="1"
												autocomplete="on"
												value={data?.source_name || ""}
												onChange={handleChange}
											/>
											<div className={css.vm_validator}>
												<div
													className={css.vm_validator_circle}
													style={
														validator1 === "gray"
															? { backgroundColor: "var(--validation-default)" }
															: validator1 === "green"
															? { backgroundColor: "var(--validation-success)" }
															: validator1 === "orange"
															? { backgroundColor: "var(--validation-warn)" }
															: { backgroundColor: "var(--validation-error)" }
													}
													onClick={() => {
														if (data?.source_name === "" || !data?.source_name) {
															setValidator1("red");
														}
													}}
												></div>
											</div>
										</div>{" "}
									</div>
									<div
										className={classNames(
											css.vm_field_wrapper,
											css.vm_field,
											css.vm_field_link_page,
											css.required,
										)}
									>
										<label className={css.vm_label}>Ссылка на страницу</label>
										<div className={css.vm_input_block}>
											<input
												className={classNames(css.validator, css.vm_input)}
												required={true}
												type="text"
												name="link_page"
												tabindex="1"
												autocomplete="on"
												value={data?.link_page || ""}
												onChange={handleChange}
											/>
											<div className={css.vm_validator}>
												<div
													className={css.vm_validator_circle}
													style={
														validator2 === "gray"
															? { backgroundColor: "var(--validation-default)" }
															: validator2 === "green"
															? { backgroundColor: "var(--validation-success)" }
															: validator2 === "orange"
															? { backgroundColor: "var(--validation-warn)" }
															: { backgroundColor: "var(--validation-error)" }
													}
													onClick={() => {
														if (data?.link_page === "" || !data?.link_page) {
															setValidator2("red");
														}
													}}
												></div>
											</div>
										</div>{" "}
									</div>
									<div
										className={classNames(
											css.vm_field_wrapper,
											css.vm_field,
											css.vm_field_comment,
											css.required,
										)}
									>
										<label className={css.vm_label}>Описание площадки</label>
										<div className={css.vm_input_block}>
											<textarea
												className={classNames(css.vm_input, css.vm_textarea, css.validator)}
												minlength="5"
												maxlength="50"
												required={true}
												name="comment"
												tabindex="1"
												value={data?.comment || ""}
												onChange={handleChange}
											></textarea>
											<div className={css.vm_validator}>
												<div
													className={css.vm_validator_circle}
													style={
														validator3 === "gray"
															? { backgroundColor: "var(--validation-default)" }
															: validator3 === "green"
															? { backgroundColor: "var(--validation-success)" }
															: validator3 === "orange"
															? { backgroundColor: "var(--validation-warn)" }
															: { backgroundColor: "var(--validation-error)" }
													}
													onClick={() => {
														if (data?.comment === "" || !data?.comment) {
															setValidator3("red");
														}
													}}
												></div>
											</div>
										</div>
									</div>
									<button
										className={classNames(
											css.vm_button,
											css.button,
											css.success,
											css.circle,
											css.center,
											css.padding_top,
										)}
										onClick={submit}
										type="submit"
									>
										Создать
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Source;
