/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import css from "../styles/Header.module.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";

const Header = () => {
	const [activeMenu, setActiveMenu] = useState(false);
	const [subMenuActive, setSubMenuActive] = useState(0);

	const openMenu = () => {
		setActiveMenu(!activeMenu);
	};
	return (
		<>
			<div className={css.header}>
				<div className={classNames(css.header__leftContent, css.leftContent)}>
					<div className={css.leftContent__message}>
						<svg className={css.svg}>
							<use href="icons_bundle.453fce12.svg#email"></use>
						</svg>
					</div>
					{/* <div className={css.leftContent__buttons}>
						<div className={css.leftContent__buttonsIcons}>
							<img
								src="android.163dc936.svg"
								className={classNames(css.pwaButtonIcon, css.android)}
							/>
							<img src="ios.94f1352b.svg" className={classNames(css.pwaButtonIcon, css.android)} />
						</div>
					</div> */}
				</div>
				<img className={css.logo} src="logo.806b8708.svg" />
				<div className={css.menuButton} onClick={openMenu}>
					<div className={css.burger}>
						{!activeMenu && (
							<svg className={css.svg1}>
								<use href="icons_bundle.453fce12.svg#menu"></use>
							</svg>
						)}
						{activeMenu && (
							<svg className={css.svg2}>
								<use href="icons_bundle.453fce12.svg#cross"></use>
							</svg>
						)}
					</div>
				</div>
			</div>
			<div className={classNames(css.menu, activeMenu && css._activeMenu)}>
				<div className={css.menuContent}>
					<div className={css.userInfo}>
						<div className={css.avatar}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
								className={classNames(css.icon, css.faUserAlt, css.faW16)}
							>
								<path
									fill="currentColor"
									d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z"
								></path>
							</svg>
						</div>
						<div className={classNames(css.username, css.text)}>Rau</div>
					</div>
					<div className={classNames(css.userMoney, css.group, css.shadow)}>
						<div className={classNames(css.groupItem, css.moneyContainer)}>
							<div className={css.wallet}>
								<svg className={css.svg}>
									<use href="icons_bundle.453fce12.svg#wallet"></use>
								</svg>
							</div>
							<div className={css.money}>
								<div className={css.balance}>230 720 $</div>
								<div className={css.revshare}>CPA</div>
							</div>
						</div>
						<div className={classNames(css.groupItem, css.actionContainer)}>
							<svg className={css.svg}>
								<use href="icons_bundle.453fce12.svg#right"></use>
							</svg>
						</div>
					</div>
					<div className={css.menuItemsContainer}>
						<div className={css.menuItems}>
							<Link
								to="/"
								className={classNames(css.menuItem, css.shadow)}
								onClick={() => {
									setActiveMenu(false);
								}}
							>
								<div className={css.menuItemImage}>
									<svg className={css.svg}>
										<use href="icons_bundle.453fce12.svg#dashboard"></use>
									</svg>
								</div>
								<div className={css.menuItemText}>Dashboard</div>
							</Link>
							<div
								className={classNames(
									css.menuItem,
									css.shadow,
									css.hasSubmenu,
									subMenuActive === 1 && css.activeSubMenu,
								)}
								onClick={() => setSubMenuActive(1)}
							>
								<div className={css.menuItemImage}>
									<svg className={css.svg}>
										<use href="icons_bundle.453fce12.svg#pr-tools"></use>
									</svg>
								</div>
								<div
									className={css.menuItemText}
									onClick={e => {
										e.stopPropagation();
										setSubMenuActive(0);
									}}
								>
									PR-инструменты
								</div>
								<div className={css.submenu}>
									<div className={css.submenuItem}>Промоматериалы</div>
									<div className={css.submenuItem}>Промокоды</div>
									<div className={css.submenuItem}>Ссылки</div>
								</div>
							</div>
							<Link
								to="/source"
								className={classNames(css.menuItem, css.shadow)}
								onClick={() => {
									setActiveMenu(false);
								}}
							>
								<div className={css.menuItemImage}>
									<svg className={css.svg}>
										<use href="icons_bundle.453fce12.svg#sources"></use>
									</svg>
								</div>
								<div className={css.menuItemText}>Источники</div>
							</Link>
							<div
								className={classNames(
									css.menuItem,
									css.shadow,
									css.hasSubmenu,
									subMenuActive === 2 && css.activeSubMenu,
								)}
								onClick={() => setSubMenuActive(2)}
							>
								<div className={css.menuItemImage}>
									<svg className={css.svg}>
										<use href="icons_bundle.453fce12.svg#statistics"></use>
									</svg>
								</div>
								<div
									className={css.menuItemText}
									onClick={e => {
										e.stopPropagation();
										setSubMenuActive(0);
									}}
								>
									Статистика
								</div>
								<div className={css.submenu}>
									<Link
										to={"/statistics"}
										className={css.submenuItem}
										onClick={() => {
											setActiveMenu(false);
										}}
									>
										Общая
									</Link>
								</div>
							</div>
							<div className={classNames(css.menuItem, css.shadow)}>
								<div className={css.menuItemImage}>
									<svg className={css.svg}>
										<use href="icons_bundle.453fce12.svg#withdrawal"></use>
									</svg>
								</div>
								<div className={css.menuItemText}>Выводы</div>
							</div>
							<Link
								className={classNames(css.menuItem, css.shadow)}
								to={"/faq"}
								onClick={() => {
									setActiveMenu(false);
								}}
							>
								<div className={css.menuItemImage}>
									<svg className={css.svg}>
										<use href="icons_bundle.453fce12.svg#faq"></use>
									</svg>
								</div>
								<div className={css.menuItemText}>FAQ</div>
							</Link>
							<Link
								className={classNames(css.menuItem, css.shadow)}
								to={"/contacts"}
								onClick={() => {
									setActiveMenu(false);
								}}
							>
								<div className={css.menuItemImage}>
									<svg className={css.svg}>
										<use href="icons_bundle.453fce12.svg#contacts"></use>
									</svg>
								</div>
								<div className={css.menuItemText}>Контакты</div>
							</Link>
						</div>
					</div>
					<button className={classNames(css.button, css.mini, css.circle, css.exit)}>Выход</button>
					<section
						data-v-0f1cdcc3=""
						data-v-0e08dc47=""
						className={classNames(css.dropdown, css.changeLang, css.mainChangeLanguage)}
					>
						<div data-v-0f1cdcc3="" className={css.dropdownTrigger}>
							<a className={css.changeLangTrigger}>
								<div className={css.changeLangText}>rus</div>
								<svg
									data-v-0e08dc47=""
									className={classNames(css.svg, css.changeLangIcon)}
									data-v-0f1cdcc3=""
								>
									<use href="icons_bundle.453fce12.svg#flag-ru"></use>
								</svg>
							</a>
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

export default Header;
