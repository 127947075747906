/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { ru } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import css from "../styles/CustomPicker.module.scss";
registerLocale("ru", ru);

const Picker = ({ getDate }) => {
	const [startDate, setStartDate] = useState(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
	const [endDate, setEndDate] = useState(new Date().getTime());

	const [width, setWidth] = useState(0);

	const onChange = (dates, e) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
		getDate(start, end);
	};

	useEffect(() => {
		const month = document.querySelector(".react-datepicker__month").getBoundingClientRect();
		const dayWidth = month.width / 7 - 4;

		setWidth(dayWidth);
	}, []);

	return (
		<div className={css.container} style={{ "--dayWidth": `${width}px` }}>
			<DatePicker
				selected={startDate}
				onChange={onChange}
				startDate={startDate}
				endDate={endDate}
				selectsRange={true}
				monthsShown={1}
				locale="ru"
				inline
				fixedHeight
			/>
		</div>
	);
};

export default Picker;
