/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import css from "../styles/Selector.module.scss";
import classNames from "classnames";

const Selector = ({ variables }) => {
	const [activeSelector, setActiveSelector] = useState();
	const [active, setActive] = useState(false);

	useEffect(() => {
		setActiveSelector(variables[0]);
	}, []);

	return (
		<div className={css.container}>
			<div
				className={classNames(css.title, active && css._active)}
				onClick={() => setActive(!active)}
			>
				<span>{activeSelector}</span>
				<svg className={classNames(css.svg, active && css._active)}>
					<use href="/form_icons_bondle.61ddde8b.svg#down"></use>
				</svg>
			</div>
			<div
				className={classNames(css.body, active && css._active)}
				style={active ? { height: `${variables.length * 40 + 10}px` } : {}}
			>
				{variables.map(el => {
					return (
						<div
							key={el}
							className={css.item}
							onClick={() => {
								setActiveSelector(el);
							}}
						>
							<span>{el}</span>
							{el === activeSelector && <span className={css.circle}></span>}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Selector;
